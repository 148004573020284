import React from "react";
import {
  Box,
  Link,
  LinkBoxProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { routes } from "../../routes/links";
import { useLocation } from "react-router-dom";

const NavLink = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  const props: LinkBoxProps = {
    py: "0",
    px: "0",
    borderBottomColor: "orange.500",
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
    borderRadius: "1px",
  };

  const noLine = { textDecoration: "none" };

  return (
    <Box display={"flex"} gap={12} paddingTop={4} paddingRight={7}>
      <Link
        href={routes.home}
        _hover={props}
        {...(location.pathname === routes.home ? (props as any) : {})}
      >
        Inicio
      </Link>

      <Box onMouseEnter={onOpen} onMouseLeave={onClose}>
        <Menu isOpen={isOpen}>
          <Link
            href={routes.products}
            {...(location.pathname === routes.products ? (props as any) : {})}
          >
            <MenuButton fontWeight={"semibold"}>
              Productos y Servicios
            </MenuButton>
          </Link>
          <MenuList
            color="black"
            fontSize="sm"
            py="0"
            px="0"
            borderTopColor="orange.500"
            borderTopStyle="solid"
            borderTopWidth="3px"
            borderRadius="1px"
          >
            <Link href={routes.wallet} style={noLine}>
              <MenuItem>PagoFlash Wallet Persona</MenuItem>
            </Link>

            <Link
              href="https://play.google.com/store/apps/details?id=com.pagoflashwallet"
              isExternal
              style={noLine}
            >
              <MenuItem
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <span
                  style={{
                    borderLeftColor: "orange.500",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "2px",
                    paddingLeft: "1rem",
                  }}
                >
                  PagoFlash Wallet APP
                </span>
              </MenuItem>
            </Link>

            <Link href={routes.business} style={noLine}>
              <MenuItem>PagoFlash Business</MenuItem>
            </Link>

            <Link href={routes.commerce} style={noLine}>
              <MenuItem
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <span
                  style={{
                    borderLeftColor: "orange.500",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "2px",
                    paddingLeft: "1rem",
                  }}
                >
                  PagoFlash Commerce
                </span>
              </MenuItem>
            </Link>

            <Link href={routes.nomina} style={noLine}>
              <MenuItem
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <span
                  style={{
                    borderLeftColor: "orange.500",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "2px",
                    paddingLeft: "1rem",
                  }}
                >
                  PagoFlash Nómina
                </span>
              </MenuItem>
            </Link>
          </MenuList>
        </Menu>
      </Box>

      <Link
        href={routes.soporte}
        _hover={props}
        {...(location.pathname === routes.soporte ? (props as any) : {})}
      >
        Soporte
      </Link>

      <Link
        href="https://docs.pagoflash.com/"
        _hover={props}
        isExternal
      >
        Referencia del API
      </Link>

      <Link
        href="https://www.pagoflash.com/payment-commerce/login"
        _hover={props}
        isExternal
      >
        Iniciar Sesión
      </Link>
    </Box>
  );
};

export default NavLink;
