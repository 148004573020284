import { Box, Text } from "@chakra-ui/react";
import Footer from "../components/layout/Footer";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import axios from "axios";

const TermsAndConditionsPage = () => {
  const [terms, setTerms] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null as any);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await axios.get(
          "https://qa.pagoflash.com/payment-gateway-wallet/api/Config/terms"
        );
        console.log("response", response);

        setTerms(response.data.result.content);
      } catch (error) {
        setError(error);
        console.log("Error al cargar los Terminos y Condiciones", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTerms();
  }, []);
  return (
    <Box>
      <Box py="32">
        <Box maxW="4xl" mx="auto" boxShadow="base" py="8" px="16">
          {isLoading ? (
            <Text>Cargando términos y condiciones...</Text>
          ) : error ? (
            <Text>
              Error al cargar los términos y condiciones. Intente más tarde
            </Text>
          ) : terms ? (
            <Markdown className="markdown">{terms}</Markdown>
          ) : (
            <Text>No se han encontrado los términos y condiciones</Text>
          )}
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default TermsAndConditionsPage;
